@mixin ellipsize_text($num_lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* number of lines to show */
    -webkit-line-clamp: $num_lines;
    -webkit-box-orient: vertical;
}

.text-clipped-1 {
    @include ellipsize_text(1);
}

.text-clipped-2 {
    @include ellipsize_text(2);
}

.text-clipped-3 {
    @include ellipsize_text(3);
}

.text-clipped-4 {
    @include ellipsize_text(4);
}

.text-clipped-5 {
    @include ellipsize_text(5);
}

.text-clipped-6 {
    @include ellipsize_text(6);
}

.text-clipped-7 {
    @include ellipsize_text(7);
}

.text-clipped-8 {
    @include ellipsize_text(8);
}

.text-clipped-9 {
    @include ellipsize_text(9);
}

.text-clipped-10 {
    @include ellipsize_text(10);
}

.text-clipped-11 {
    @include ellipsize_text(11);
}

.text-clipped-12 {
    @include ellipsize_text(12);
}

.text-clipped-13 {
    @include ellipsize_text(13);
}

.text-clipped-14 {
    @include ellipsize_text(14);
}
