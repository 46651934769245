@import "../../CategoryCarousel.module.scss";

.carousel-card {
    position: relative;
    width: $CARD_WIDTH;
    margin: get_margin($CARD_OFFSET, $CARD_MARGIN);
    cursor: pointer;

    &:first-child {
        margin-left: 0px;
    }

    &--mobile {
        @extend .carousel-card;
        width: $CARD_WIDTH_MOBILE;
        margin: get_margin($CARD_OFFSET_MOBILE, $CARD_MARGIN_MOBILE);
    }

    &__view-all {
        text-align: center;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #018f5c;
        white-space: nowrap;
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: white;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 0.3;
        }
    }
}
