@import "../../../../lib/mixins.scss";
@import "../../CategoryCarousel.module.scss";

.content-preview-default {
    position: relative;
    flex-grow: 1;
    border-radius: 8px;
    height: $CARD_HEIGHT;
    width: $CARD_WIDTH;
    margin: 0px;
    background-color: #d3d3d3;
    cursor: pointer;

    &__image {
        height: $CARD_HEIGHT;
        width: $CARD_WIDTH;
        border-radius: 8px;
        object-fit: cover;
    }

    &__metadata-container {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 20px;
        background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.7) 30%,
            rgba(0, 0, 0, 0.6) 45%,
            rgba(0, 0, 0, 0.5) 60%,
            rgba(0, 0, 0, 0.3) 80%,
            transparent 100%
        );
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    &__title {
        color: #fbfbfb;
        font-family: Roboto, sans-serif;
        margin: 0px;
        flex-grow: 1;
        @include ellipsize_text(2);
    }

    &__vertical-divider {
        height: 50px;
        width: 1px;
        background-color: #fbfbfb;
        margin-left: 10px;
        margin-right: 10px;
    }

    &__author {
        font-family: Roboto, sans-serif;
        font-size: 11px;
        color: #fbfbfb;
        margin: 0px;
        margin-bottom: 2px;
        width: 50px;
        margin-left: 10px;
    }
}
