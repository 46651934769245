@import "./CategoryCarousel.module.scss";

.category-carousel {
    width: 100%;
    margin: 25px 0px;
    background-color: #efeeee;

    &__header {
        width: 100%;
        padding: $CARD_OFFSET + $CARD_MARGIN $CARD_MARGIN;
        padding-bottom: 0px;

        &--mql {
            @extend .category-carousel__header;
        }
    }

    &__title {
        font-family: Roboto, sans-serif;
        color: #003023;
        font-size: 30px;
    }

    &__carousel-scroller-container {
        width: 100%;
        height: $CAROUSEL_HEIGHT;
        overflow: hidden;

        &--mobile {
            @extend .category-carousel__carousel-scroller-container;
            height: $CAROUSEL_HEIGHT_MOBILE;
        }
    }

    &__carousel-scroller {
        position: relative;
        width: 100%;
        height: 100%;
        padding: $CARD_MARGIN;
        overflow-x: scroll;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &--mobile {
            @extend .category-carousel__carousel-scroller;
            padding: 0px $CARD_OFFSET_MOBILE;
        }

        &--loading {
            @extend .category-carousel__carousel-scroller;
            overflow: hidden;
        }
    }
}
