$CARD_HEIGHT: 150px;
$CARD_HEIGHT_MOBILE: 150px;
$CARD_WIDTH: 250px;
$CARD_WIDTH_MOBILE: 250px;
$CARD_MARGIN: 15px;
$CARD_MARGIN_MOBILE: 10px;
$CARD_OFFSET: 15px;
$CARD_OFFSET_MOBILE: 10px;
$CAROUSEL_HEIGHT: $CARD_HEIGHT + 2 * $CARD_OFFSET + 3 * $CARD_MARGIN;
$CAROUSEL_HEIGHT_MOBILE: $CARD_HEIGHT_MOBILE + 2 * $CARD_OFFSET_MOBILE + 3 * $CARD_MARGIN_MOBILE;

@function get_margin($card_offset, $card_margin) {
    @return $card_offset 0px ($card_offset + $card_margin) $card_margin;
}

:export {
    CARD_WIDTH: $CARD_WIDTH;
    CARD_WIDTH_MOBILE: $CARD_WIDTH_MOBILE;
    CARD_HEIGHT: $CARD_HEIGHT;
    CARD_HEIGHT_MOBILE: $CARD_HEIGHT_MOBILE;
    CAROUSEL_HEIGHT: $CAROUSEL_HEIGHT;
    CAROUSEL_HEIGHT_MOBILE: $CAROUSEL_HEIGHT_MOBILE;
    CARD_MARGIN: $CARD_MARGIN;
    CARD_MARGIN_MOBILE: $CARD_MARGIN_MOBILE;
    CARD_OFFSET: $CARD_OFFSET;
    CARD_OFFSET_MOBILE: $CARD_OFFSET_MOBILE;
}
