.carousel-arrows {
    cursor: pointer;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    &__left {
        @extend .carousel-arrows;
        margin-right: 10px;
    }

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.3;
    }

    &--disabled {
        cursor: not-allowed;
    }
}
